import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import { Lazyload } from 'vant'
import { Toast } from 'vant';

const app = createApp(App)

app.use(Lazyload)
app.use(Toast)

app.mount('#app')