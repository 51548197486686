<script setup>

import { ref } from 'vue';
import axios from 'axios';


const activeIndex = ref(0);

const activeCateItems = ref([])
const currentCate1 = ref('')
const currentCate2 = ref('')
const currentCate2Files = ref([])
const isLoading = ref(false)

let objs = ref([])   //存放二级目录下的对象
let items = ref([{ text: "全部" }])
let cate1Name = ref('')
let cate2Name = ref('')

const show = ref(false)
const showWxId = ref(false)

const editMode = ref(false)

function getQueryParam(name) {
  var params = window.location.search.substring(1).split('&');
  for (var i = 0; i < params.length; i++) {
    var param = params[i].split('=');
    if (param[0] === name) {
      return decodeURIComponent(param[1]);
    }
  }
  return null;
}

const editSecret = getQueryParam("editmode")

if (editSecret == '98uhwud8dq12wqdsaasdasd1') {
  editMode.value = true
}

const axios_h = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + getQueryParam("editmode")
  }
});

const initCate1 = function () {
  items.value = [{ text: "全部" }]
  axios_h.get('/list').then((res) => {
    for (const index in res.data) {
      let item = { text: clearName(res.data[index]), path: res.data[index] }
      items.value.push(item)
    }

  })
}


const listCate2 = async function (index) {

  objs.value = []
  activeCateItems.value = []
  currentCate1.value = items.value[index].path

  if (index == 0) {
    const res0 = await axios_h.get('/listalldir')
    activeCateItems.value = res0.data
  } else {
    const res1 = await axios_h.get('/listdir?dir=' + currentCate1.value)
    console.log(res1)
    activeCateItems.value = res1.data
  }

  //找到二级目录对应的所有照片对象
  for (const i in activeCateItems.value) {
    const res2 = await axios_h.get('/listobj?dir=' + activeCateItems.value[i])
    objs.value[i] = res2.data
  }


}

initCate1()
listCate2(0)


//打开二级目录下的图片库
const showCateFiles = function (cate2) {
  currentCate2.value = cate2
  axios_h.get('/listobj?dir=' + cate2).then(files => {
    currentCate2Files.value = files.data.filter(n => !n.name.endsWith('/'))
    show.value = false
    show.value = true
  })
}


//
const addCate = function (cate_name, refresh) {

  cate1Name.value = ''
  cate2Name.value = ''

  if (cate_name.length == 0) {
    return
  }

  axios_h.post('/addcate', { cate_name }).then(rs => {
    alert("成功添加");
    refresh()
  })
}

//删除对象
const delObj = function (name, refresh) {
  axios_h.post('/delobj', { name }).then(rs => {
    alert("删除成功");
    refresh()
  })
}


const reload = function () {
  window.location.reload()
}


const addCate1 = function (cate_name) {

  if (cate_name.trim().length == 0) {
    return false
  }

  addCate('lzc/' + cate_name.trim() + '/', () => { reload() })

}


const addCate2 = function (cate_name) {
  if (cate_name.trim().length == 0) {
    return false
  }
  addCate(currentCate1.value + cate_name.trim() + '/', () => {
    listCate2(activeIndex.value)
  })
}


//路径去头去尾
const clearName = function (path) {
  return path.substring(4, path.length - 1)
}

const afterUpload = () => {
  showCateFiles(currentCate2.value)
  listCate2(activeIndex.value)
}

const beforeRead = (files) => {
  console.log(files)

  if (!Array.isArray(files)) {
    files = [files]
  }


  for (const i in files) {
    // 检查文件类型
    if (files[i].type !== 'image/jpeg') {
      showToast('请上传 JPG 格式图片')
      isLoading.value = false
      return false
    }

    // 检查文件大小
    if (files[i].size > 5000 * 1024) {
      showToast('上传图片太大，不得超过5MB')
      isLoading.value = false
      return false
    }

  }

  return true
}


//选择文件后上传
const afterRead = (files) => {
  isLoading.value = true
  if (!Array.isArray(files)) {
    uploadFile(files).then(results => {
      console.log('文件上传成功:', results)
      // 所有文件上传成功后执行的操作
      afterUpload()
      isLoading.value = false
    })
      .catch(error => {
        console.log('文件上传失败:', error)
        // 处理上传失败的情况
        afterUpload()
        isLoading.value = false
      })
    return
  }

  const uploadPromises = []

  for (var i in files) {
    uploadPromises[uploadPromises.length] = uploadFile(files[i])
  }


  Promise.all(uploadPromises)
    .then(results => {
      console.log('所有文件上传成功:', results);
      // 所有文件上传成功后执行的操作
      afterUpload()
      isLoading.value = false
    })
    .catch(error => {
      console.log('文件上传失败:', error);
      // 处理上传失败的情况
      afterUpload()
      isLoading.value = false
    });


};

const uploadFile = (obj) => {
  return new Promise((resolve, reject) => {
    const file = obj.file;

    const xhr = new XMLHttpRequest()
    xhr.open("POST", "/upload")  // 指定上传接口

    // 设置请求头
    xhr.setRequestHeader('Authorization', 'Bearer ' + editSecret)

    xhr.onload = function () {
      if (xhr.status === 200) {
        resolve(xhr.response)
      } else {
        reject('上传失败，错误码：' + xhr.status)
      }
    }

    xhr.onerror = function () {
      reject('服务器请求失败')
    }

    // 创建 FormData 对象来封装文件数据
    const formData = new FormData()
    formData.append("path", currentCate2.value)
    formData.append("myFile", file)

    xhr.send(formData);  // 发送请求
  })

};

const uploadFilex = (obj) => {
  const file = obj.file
  if (file.type !== 'image/jpeg') {
    alert('请上传 JPG 格式图片');
    return false;
  }
  // 对比文件大小等
  if (file.size > 5000 * 1024) {
    alert('上传图片太大，不得超过5MB');
    return false;
  }

  var xhr = new XMLHttpRequest();
  xhr.open("POST", "/upload");  // 指定上传接口

  xhr.setRequestHeader('Authorization', 'Bearer ' + editSecret);
  xhr.onload = function () {
    if (xhr.status === 200) {

      alert("上传成功");

      //showCateFiles(currentCate2.value)
      //listCate2(activeIndex.value)

      //isLoading.value = false

    } else {
      alert("上传失败，错误码：" + xhr.status);
    }
  }

  xhr.onerror = function () {
    alert("请求失败");
  }

  // 创建 FormData 对象来封装文件数据
  var formData = new FormData();
  formData.append("path", currentCate2.value);
  formData.append("myFile", file);

  xhr.send(formData);  // 发送请求

}


</script>


<template>

  <div style="padding-left:1rem;padding-top:1rem;color:#fff;background: #222;border-bottom: 1rem solid #262626;">
    <p style="font-size: 2rem;">芥末摄影&nbsp<span style="font-size: 0.8rem;" class="icp"
        onclick="location.href='https://beian.miit.gov.cn/'">粤ICP备2024278315号-1</span></p>
    <p class="icons"><a href="tel:15113739189"><i class="fa fa-phone" location.href=""></i></a><i
        class="fa-brands fa-weixin" @click="showWxId = true"></i><i class="fa-solid fa-location-dot"
        onclick="location.href='http://zxcms.cnzxwh.com/index.php?r=service/locate&lid=181'"></i></p>
  </div>

  <div style="position: absolute;left: 1rem;bottom:1rem;z-index:999" v-if="editMode">
    <input type="text" v-model="cate1Name" placeholder="输入一级目录名称" style="width: 10rem;"><br>
    <button @click="addCate1(cate1Name);" style="width: 10rem;">添加一级目录</button>
  </div>

  <van-tree-select v-model:main-active-index="activeIndex" height="100%" :items="items" @click-nav="listCate2">

    <template #content>

      <button style="margin-top: 1rem;" v-if="editMode && activeIndex != 0"
        @click="delObj(items[activeIndex].path, () => { reload() });"> x 删除【{{
          items[activeIndex].text }}】 </button>


      <div v-if="editMode && activeIndex != 0" style="margin: 1rem 0;">
        <input type="text" v-model="cate2Name" placeholder="输入二级目录名称" style="width: 10rem;"><br>
        <button @click="addCate2(cate2Name)" style="width: 10rem;">添加二级目录</button>
      </div>

      <div v-if="activeCateItems.length > 0" v-for="(item, index) in activeCateItems">

        <div style="width: 100%;height:15rem;background: #ddd;position: relative;">

          <div style="width: 100%;height:15rem;display: flex;flex-direction: row;" @click="showCateFiles(item)">

            <img style="flex-shrink: 0;height: 100%;"
              v-lazy="objs[index][1].url + '?x-oss-process=image/resize,l_1000/format,webp'"
              v-if="objs && objs.length > index && objs[index].length > 1" />

            <img style="flex-shrink: 0;height: 100%;"
              v-lazy="objs[index][2].url + '?x-oss-process=image/resize,l_1000/format,webp'"
              v-if="objs && objs.length > index && objs[index].length > 2" />

            <img style="flex-shrink: 0;height: 100%;"
              v-lazy="objs[index][3].url + '?x-oss-process=image/resize,l_1000/format,webp'"
              v-if="objs && objs.length > index && objs[index].length > 3" />



            <div v-if="objs && objs.length > index && objs[index].length <= 1"
              style="font-size: 1.5rem;font-weight: bold;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);color:#b4b4b4">
              暂未添加照片</div>
          </div>

          <div style="position: absolute;right: 0;bottom:0;font-size: 1.1rem;">
            <span
              style="background: #000;color:#fff;display: inline-block;box-sizing: border-box;padding:0.2rem 1rem;">{{
                clearName(item) }} </span>
          </div>



          <div style="position: absolute;left: 0;bottom:0;font-size: 1.1rem;"
            @click="delObj(item, () => { listCate2(activeIndex) });" v-if="editMode">
            <span
              style="background: red;color:#fff;display: inline-block;box-sizing: border-box;padding:0.2rem 0.5rem;">
              x 删除
            </span>
          </div>



        </div>



      </div>

      <div style="height:15rem"></div>

    </template>
  </van-tree-select>



  <!-- 弹出层 -->
  <van-popup v-model:show="show" closeable position="bottom" :close-on-click-overlay="false"
    :style="{ height: '100%', maxWidth: '1024px', background: '#000', left: 0, right: 0, margin: '0 auto' }">

    <div
      style="position: absolute; bottom:1rem;left:0;right:0;margin:0 auto; z-index: 99999;width:90%;text-align: center;"
      v-if="editMode">
      <van-uploader :after-read="afterRead" multiple :before-read="beforeRead">
        <van-button icon="plus" type="primary">上传JPG格式照片(不大于5MB)</van-button>
      </van-uploader>
    </div>

    <div style="height:100%;overflow-y: scroll;position: relative;">
      <div
        style="height:4rem;line-height: 4rem;font-size: 1.5rem;background:#000;color:#fff;position: sticky;top:0;z-index: 1;width: 100%;;">
        &nbsp;&nbsp;{{ clearName(currentCate2) }}</div>

      <div v-for="item in currentCate2Files" style="position: relative;">

        <img v-lazy="item.url + '?x-oss-process=image/resize,l_3000'" width="100%" class="imglist" />

        <div style="position: absolute;right: 0;top:0;font-size: 1.1rem;"
          @click="delObj(item.name, () => { showCateFiles(currentCate2); listCate2(activeIndex) });" v-if="editMode">
          <span style="background: red;color:#fff;display: inline-block;box-sizing: border-box;padding:0.2rem 0.5rem;">
            x 删除
          </span>
        </div>

      </div>

    </div>

  </van-popup>



  <!-- 弹出层2 -->
  <van-popup v-model:show="showWxId" closeable position="bottom" :close-on-click-overlay="true"
    :style="{ height: '60%', maxWidth: '1024px', background: '#000', left: 0, right: 0, margin: '0 auto' }">


    <img src="/wxid.jpg" alt="" style="width: 100%;">

  </van-popup>

  <van-popup v-model:show="isLoading" :style="{ padding: '64px' }" :close-on-click-overlay="false" round="true"
    lazy-render="false">
    <van-loading size="32px" vertical>努力上传中...</van-loading>
  </van-popup>


</template>

<style scoped>
button {
  font-size: 1.1rem;
  height: 2rem;
  line-height: 1.5rem;
  padding: 0.1rem 0.5rem;
  display: inline-block;
  border: none;

}

button:active {
  background: #de4100;
}

img {
  display: block;
}

input {
  font-size: 1.1rem;
  border-radius: 0;
  border: none;
  padding: 0.1rem 0.5rem;
  height: 2rem;
  line-height: 1.5rem;
  display: inline-block;
  box-sizing: border-box;

}

.icp:hover {
  cursor: pointer;
}

.icp {

  display: inline-block;
}

.icons i,
.icons a i {

  font-size: 1.8rem;
  padding-right: 1.3rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

}

.icons a {
  color: #fff;
}

.imglist {
  margin-bottom: 3px;
}
</style>
